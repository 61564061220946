#root {
  height: 100vh;
}

html {
  height: 100vh;
}

body {
  height: 100vh;
}

html.dark {
  background-color: rgb(15, 23, 42);
}
