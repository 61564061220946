@import '../../index.css';

.feedbackButton {
  transition: all 0.2s;
}

.feedbackButton:hover {
  transform: scale(1.05, 1.05);
}

.feedbackButton:focus-visible {
  outline: none;
}

.feebackButton button:focus-visible {
  outline: none;
}
